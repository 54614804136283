<template>
	<div class="material-container">
		<div class="material-menu">
			<div class="material-menu-main">
				<el-scrollbar>
					<el-tree
						:data="folderList"
						ref="folderList"
						node-key="material_id"
						:default-expanded-keys="defaultExpandedKeys"
						:default-checked-keys="defaultCheckedKeys"
						:current-node-key="currentNodekey"
						:props="{label:'name'}"
						@current-change="handleFolderCurrentChange">
					</el-tree>
				</el-scrollbar>
			</div>
			<div class="material-menu-foot">
				<ul>
					<li><div @click="handleFolderShow"><i class="el-icon-plus"></i></div></li>
					<!-- <li><div class="pic-del"><i class="el-icon-delete"></i><span>{{$t('seller.material.retrieve')}}</span></div></li> -->
				</ul>
			</div>
		</div>
		<div class="material-main">
			<div class="material-wrap">
				<!-- <div class="material-wrap-notice">公告内容</a></div> -->
				<div class="material-wrap-head">
					<div class="material-wrap-head-main">
						<ul>
							<li><span class="uu-icon uu-icon-vertical active"></span></li>
							<!-- <li><span class="uu-icon uu-icon-level"></span></li>
							<li class="clera"></li>
							<li class="material-wrap-head-rank">
								<span class="uu-icon uu-icon-sort"></span>
							</li> -->
							<li>
								<el-popover
									placement="bottom"
									trigger="click">
									<div class="popover-pic-screen">
										<ul>
											<li>
												<div class="item-tit">{{$t('seller.screen.showMaterial')}}</div>
												<div class="item-main">
													<el-radio-group v-model="picScreen.status" @change="handleMaterialTypeChange">
														<el-radio v-for="(item,index) in statusOption" :key="index" :label="item.value">{{item.label}}</el-radio>
													</el-radio-group>
												</div>
											</li>
										</ul>
									</div>
									<el-input slot="reference" v-model="screenwork" readonly size="mini" :placeholder="$t('seller.placeholder.unfiltered')">
									</el-input>
								</el-popover>
							</li>
							<li>
								<el-input slot="reference" v-model="keyword" size="mini" :placeholder="$t('seller.placeholder.material')" @change="handleSeachKeyword">
									<i slot="prefix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="handleSeachKeyword"></i>
								</el-input>
								<!-- <el-popover
									placement="bottom"
									v-model="sarchVisible "
									trigger="click">
									<div class="popover-pic-sarch">
										<div class="sarch-title">高级搜索</div>
										<div class="sarch-item">
											<span class="item-label">搜索模式</span>
											<div class="item-block">
												<el-radio v-model="picSarch.status" label="1">按图片/文件夹搜索</el-radio>
												<el-radio v-model="picSarch.status" label="2">按宝贝名称搜索</el-radio>
											</div>
										</div>
										<div class="sarch-item">
											<span class="item-label">上传时间</span>
											<div class="item-block">
											</div>
										</div>
										<div class="sarch-item">
											<span class="item-label">搜索关键字</span>
											<div class="item-block">
												<el-input size="mini" v-model="keyword" :placeholder="$t('seller.placeholder.keyword')"></el-input>
											</div>
										</div>
										<div class="popover-pic-sarch-foot">
											<el-button size="mini" @click="sarchVisible = false">{{$t('seller.actions.cancel')}}</el-button>
											<el-button type="primary" size="mini">{{$t('seller.actions.confirm')}}</el-button>
										</div>
									</div>
									<el-input slot="reference" v-model="keyword" size="mini" readonly :placeholder="$t('seller.placeholder.material')">
										<i slot="prefix" class="el-input__icon el-icon-search"></i>
									</el-input>
								</el-popover> -->
							</li>
							<!-- <li><a href="#"><i class="el-icon-setting"></i>更多设置</a></li> -->
						</ul>
					</div>
					<div class="material-wrap-head-bar">
						<el-button @click="handleImagesShow" size="mini">{{$t('seller.actions.upload')}}</el-button>
						<el-button @click="handleFolderShow" size="mini">{{$t('seller.actions.newFile')}}</el-button>
					</div>
				</div>
				<div class="material-wrap-action">
					<div class="material-wrap-action-main">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<template v-if="breadcrumbList.length>0">
								<el-breadcrumb-item v-for="(item,index) in breadcrumbList" :key="index">{{item.name?item.name:'全部图片'}}</el-breadcrumb-item>
							</template>
							<template v-else>
								<el-breadcrumb-item >{{breadcrumbText}}</el-breadcrumb-item>
							</template>
						</el-breadcrumb>
						<div class="material-wrap-action-sele">
							<el-checkbox v-model="checkThumbAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">&nbsp;</el-checkbox>
							<template v-if="checkedThumb.length>0">
								<span class="con-sele-text">{{$t('seller.material.checkText1')}}{{checkedThumb.length}}{{$t('seller.material.checkText2')}}</span>
								<div class="con-sele-button">
									<span class="sele-button" @click="handleThumbAction(0,0)">{{$t('seller.actions.delete')}}</span>
									<em class="sele-icon">|</em>
									<span class="sele-button" @click="handleShiftShow">{{$t('seller.actions.moveTo')}}</span>
								</div>
							</template>
							<template v-else>全部</template>
						</div>
					</div>
					<!-- <div class="material-wrap-action-box">
						<a href="javascript:void(0);">使用说明</a>
						<div class="pic-storage">
							<p class="storage-content">已用&nbsp;22.19M/&nbsp;1.00G<a href="javascript:void(0);"><i class="el-icon-star-off"></i>升级空间</a></p>
							<p class="storage-bar"><span class="storage-bar-used" style="width: 10%;"></span></p>
						</div>
					</div> -->
				</div>
				<el-scrollbar class="material-wrap-main">
					<div class="material-list" v-if="thumbList&&thumbList.length>0">
						<el-checkbox-group v-model="checkedThumb" @change="handleCheckedThumbChange">
							<ul>
								<li v-for="(item,index) in thumbList" :key="index">
									<div class="li-img">
										<el-checkbox :label="item.material_id">
											<img v-if="item.type==3" src="@/assets/images/seller/folder-80-68.png" alt="">
											<img v-else-if="item.type==2" @dblclick="handleOpenFolder(item)" src="@/assets/images/seller/folder-80-68.png" alt="">
											<img v-else-if="item.type==1" src="@/assets/images/seller/folder-80-68.png" alt="">
											<img v-else @dblclick="handleOpenImage(item)" :src="item.url" alt="">
										</el-checkbox>
										<div class="li-img-foot">
											<el-tooltip :content="$t('seller.actions.rename')" v-if="item.type==2">
												<a class="el-icon-edit-outline" @click="handleFolderEditShow(item)"></a>
											</el-tooltip>
											<el-tooltip :content="$t('seller.actions.copy')" v-if="item.type==0">
												<a class="el-icon-copy-document" @click="handleCopy(item.url)"></a>
											</el-tooltip> |
											<el-tooltip :content="$t('seller.actions.delete')">
												<a class="el-icon-delete" @click="handleThumbAction(item.material_id,0)"></a>
											</el-tooltip>
										</div>
									</div>
									<div class="li-name">{{item.name}}</div>
								</li>
							</ul>
						</el-checkbox-group>
					</div>
					<div v-else>
						<el-empty :description="$t('seller.empty.material')"></el-empty>
					</div>
				</el-scrollbar>
				<div class="material-wrap-foot">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="pagecurr"
						:page-size="pagesize"
						layout="total, prev, pager, next"
						:prev-text="$t('seller.pagination.prevText')"
						:next-text="$t('seller.pagination.nextText')"
						:total="listTotal">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<el-dialog
			:title="$t('seller.dialogTitle.uploadImg')"
			:visible.sync="dialogImagesVisible"
			width="960px"
			class="dialog-images"
			:before-close="handleImagesClose">
			<div class="dialog-images-head">
				<ul>
					<li>
						<span class="li-label">{{$t('seller.material.uploadTo')}}</span>
						<div class="li-block w180">
							<el-popover
								v-model="popoverDialogImages"
								placement="bottom-start"
								width="180"
								trigger="click">
								<template slot="reference">
									<el-input v-model="uploadForm.pname" size="mini" readonly></el-input>
								</template>
								<el-tree
									:data="folderList"
									ref="imgTree"
									node-key="material_id"
									:default-expanded-keys="defaultExpandedKeys"
									:default-checked-keys="defaultCheckedKeys"
									:current-node-key="currentNodekey"
									:props="{label:'name'}"
									class="uu-tree"
									@current-change="handleImagesPopoverCurrentChange">
								</el-tree>
							</el-popover>
						</div>
					</li>
					<li><a @click="dialogFolderVisible = true">{{$t('seller.actions.newFolder')}}</a></li>
				</ul>
			</div>
			<el-upload
				class="dialog-material-main"
				drag
				action="#"
				multiple
				v-loading="loadingUplaod"
				:show-file-list="false"
				:http-request="httpRequestMaterialUpload">
				<img class="el-upload__pic" src="@/assets/images/seller/upload-200-119.png" alt="">
				<div class="el-upload__text">
					<p>{{$t('seller.material.uploadText1')}}<em>{{$t('seller.material.uploadText2')}}</em></p>
					<p>{{$t('seller.material.uploadText3')}}</p>
					<p><strong>{{$t('seller.material.uploadText4')}}</strong></p>
				</div>
			</el-upload>
			<div slot="footer" class="dialog-material-foot">
				<!-- <a href="javascript:void(0);"><i class="el-icon-warning-outline"></i>查看图片上传教程说明</a> -->
				<div class="dialog-material-footbar">
					<el-button @click="handleImagesClose" size="mini">{{$t('seller.actions.cancel')}}</el-button>
					<el-button type="primary" @click="handleImagesClose" size="mini">{{$t('seller.actions.confirm')}}</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			:title="$t('seller.dialogTitle.newFolder')"
			:visible.sync="dialogFolderVisible"
			width="700px"
			class="dialog-folder"
			:before-close="handleFolderClose">
			<div class="dialog-folder-main">
				<div class="dialog-folder-title">{{$t('seller.material.parentFolder')}}</div>
				<el-popover
					v-model="popoverDialogFolder"
					placement="bottom-start"
					width="394"
					trigger="click">
					<template slot="reference">
						<el-input v-model="folderForm.pname" readonly></el-input>
					</template>
					<el-tree
						:data="folderList"
						node-key="material_id"
						:default-expanded-keys="defaultExpandedKeys"
						:default-checked-keys="defaultCheckedKeys"
						:current-node-key="currentNodekey"
						:props="{label:'name'}"
						ref="addFolderTree"
						class="uu-tree"
						@current-change="handleFolderPopoverCurrentChange">
					</el-tree>
				</el-popover>
				<div class="dialog-folder-title">{{$t('seller.material.groupName')}}</div>
				<el-input v-model="folderForm.name" maxlength="20" show-word-limit :placeholder="$t('seller.placeholder.folderName')"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleFolderClose" size="mini">{{$t('seller.actions.cancel')}}</el-button>
				<el-button type="primary" @click="handleFolderSubmit" size="mini">{{$t('seller.actions.confirm')}}</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="$t('seller.dialogTitle.renameFolder')"
			:visible.sync="dialogFolderEditVisible"
			width="700px"
			class="dialog-folder"
			:before-close="handleFolderEditClose">
			<div class="dialog-folder-main">
				<div class="dialog-folder-title">{{$t('seller.material.renameFolder')}}</div>
				<el-input v-model="folderItem.name" maxlength="20" show-word-limit :placeholder="$t('seller.placeholder.groupName')"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleFolderEditClose" size="mini">{{$t('seller.actions.cancel')}}</el-button>
				<el-button type="primary" @click="handleFolderEditSubmit" size="mini">{{$t('seller.actions.confirm')}}</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="$t('seller.dialogTitle.folderMove')"
			:visible.sync="dialogShiftVisible"
			width="700px"
			class="dialog-shift"
			:before-close="handleShiftClose">
			<div class="dialog-shift-main">
				<el-scrollbar>
					<el-tree
						:data="folderList"
						node-key="material_id"
						:default-expanded-keys="defaultExpandedKeys"
						:default-checked-keys="defaultCheckedKeys"
						:current-node-key="currentNodekey"
						:props="{label:'name'}"
						@current-change="handleShiftCurrentChange">
					</el-tree>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleShiftClose" size="mini">{{$t('seller.actions.cancel')}}</el-button>
				<el-button type="primary" @click="handleShiftSubmit" size="mini">{{$t('seller.actions.confirm')}}</el-button>
			</span>
		</el-dialog>
		<el-dialog
			width="540px"
			:visible.sync="dialogThumbVisible"
			:before-close="handleThumbClose"
			@open="dialogThumbOpen">
			<el-carousel ref="thumbCarousel" height="500px" :initial-index="thumbIndex" indicator-position="none" :autoplay="false" arrow="always">
				<template v-for="(item,index) in thumbView">
					<el-carousel-item :key="index" v-if="item.type==0" name="index" >
						<img :src="item.url" alt="" width="100%" height="100%" style="object-fit: contain;">
					</el-carousel-item>
				</template>
			</el-carousel>
		</el-dialog>
	</div>
</template>

<script>
import { fetchMetarial, updateMetarialStatus, deleteMetarial, listFolder, createFolder, renameFolder,} from '@/api/seller/decorate'
import { materialOSSUpload } from '@/utils/upload'
export default {
	data() {
		return {
			folderList: [],
			defaultExpandedKeys: [],
			defaultCheckedKeys: [],
			currentNodekey: 1,
			breadcrumbList: [],
			breadcrumbListNew: [],
			breadcrumbText: '',
			screenwork: '未筛选',
			keyword: '',
			picScreen: {
				status: '-1',
				examine: '1',
				channel: '1',
			},
			picSarch: {
				status: '1',
			},
			statusOption: this.$t('seller.material.statusOption'),
			thumbView: [],
			thumbList: [],
			listTotal: 0,
			pagecurr: 1,
			pagesize: 20,
			checkThumbAll: false,
			checkedThumb: [], // 选择ID
			isIndeterminate: false,
			// 弹窗
			dialogImagesVisible: false,
			popoverDialogImages: false,
			uploadForm: {
				pid: '',
				pname: '',
			},
			loadingUplaod: false,
			dialogFolderVisible: false,
			popoverDialogFolder: false,
			folderForm: {
				pid: '',
				pname: '',
				name: '',
			},
			dialogShiftVisible: false,
			shiftForm: {
				id: '',
			},
			dialogFolderEditVisible: false,
			folderItem: {},
			sarchVisible: false,
			loading: this.$loading(),
			dialogThumbVisible: false,
			thumbIndex: 0,
		}
	},
	created() {
		this.getFolderList();
	},
	methods: {
		getFolderList(){
			listFolder({uuid: this.$store.getters.uuid,}).then(response => {
				const { data } = response
				this.folderList = data.list
				this.defaultExpandedKeys.push(data.list[0].material_id);
				this.defaultCheckedKeys.push(data.list[0].material_id);
				this.currentNodekey = this.currentNodekey?this.currentNodekey:data.list[0].material_id;
				this.currentNodename = this.currentNodename?this.currentNodename:data.list[0].name;
				this.breadcrumbText = data.list[0].name;
				this.getThumbList();
				this.$nextTick(()=>{
					this.$refs.folderList.setCurrentKey(this.currentNodekey);
				})
				this.loading.close()
			})
		},
		getThumbList(){
			let that = this;
			that.uploadForm.pid = that.currentNodekey;
			that.uploadForm.pname = that.currentNodename;
			that.folderForm.pid = that.currentNodekey;
			that.folderForm.pname = that.currentNodename;
			that.shiftForm.id = that.currentNodekey;
			let param = {
				page_index: (that.pagecurr-1)*that.pagesize,
				page_size: that.pagesize,
				parent_id: that.currentNodekey
			}
			if(that.picScreen.status){
				param.type=that.picScreen.status;
			}
			if(that.keyword){
				param.keyword=that.keyword;
			}
			fetchMetarial(param).then(response => {
				that.thumbList = response.data.list;
				that.listTotal = response.data.total;
				let thumbView = [];
				response.data.list.forEach(item=>{
					if(item.type==0){
						thumbView.push(item);
					}
				})
				that.thumbView = thumbView;
			})
		},
		handleFolderCurrentChange(data,node){
			this.currentNodename = data.name;
			this.currentNodekey = data.material_id;
			let breadcrumb = [];
			var getBreadcrumb= function(item){
				breadcrumb.unshift(item.data);
				if(item.data.parent_id>0){
					getBreadcrumb(item.parent);
				}
			}
			getBreadcrumb(node);
			this.breadcrumbList = breadcrumb;
			this.getThumbList();
			this.checkThumbAll = false;
			this.checkedThumb = [];
			this.isIndeterminate = false;
		},
		handleSizeChange(val){
			this.pagesize = val;
		},
		handleCurrentChange(val){
			this.pagecurr = val;
			this.getThumbList();
		},
		handleCheckAllChange(val){
			if(val){
				let checkId = [];
				this.thumbList.forEach(item=>{
					checkId.push(item.material_id)
				})
				this.checkedThumb = checkId;
			}else{
				this.checkedThumb = [];
			}
			this.isIndeterminate = false;
		},
		handleThumbAction(id,status){
			let that = this;
			let ids = '';
			if(id>0){
				ids = id
			}else {
				ids = that.checkedThumb.join(',');
			}
			let msgTit = this.$t('seller.confirm.deleteTitle');
			let msgText = this.$t('seller.confirm.deleteFileText');
			if(status==1){
				msgTit = this.$t('seller.confirm.recoveryTitle');
				msgText = this.$t('seller.confirm.deleteRecoveryText');
			}
			if(ids){
				that.$confirm(msgText,msgTit,{
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					let param = {
						material_ids: ids,
						status: status
					}
					updateMetarialStatus(param).then(response => {
						that.getFolderList();
						that.$message.success(this.$t('seller.successMsg.operate'));
					})
				}).catch(() => {
					that.$message(this.$t('seller.cancelMsg.operate'));
				})
			}else{
				that.$message.error(this.$t('seller.errorMsg.selectFile'));
			}
		},
		handleCheckedThumbChange(value){
			let checkedCount = value.length;
			this.checkThumbAll = checkedCount === this.thumbList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.thumbList.length;
		},
		// 我的素材
		handleMaterialTypeChange(val){
			switch(parseInt(val)){
				case 0:
				this.screenwork = '全部图片';
				break;
				default: this.screenwork = '未筛选';
			}
			this.pagecurr = 1;
			this.getThumbList();
		},
		handleSeachKeyword(){
			this.pagecurr = 1;
			this.getThumbList();
		},
		handleImagesShow(){
			this.uploadForm.pname = this.currentNodename;
			this.uploadForm.pid = this.currentNodekey;
			this.dialogImagesVisible = true;
			this.$nextTick(()=>{
				this.$refs.imgTree.setCurrentKey(this.uploadForm.pid);
			})
		},
		handleImagesClose(){
			this.currentNodekey = this.uploadForm.pid;
			this.currentNodename = this.uploadForm.pname;
			this.$nextTick(()=>{
				this.$refs.folderList.setCurrentKey(this.currentNodekey);
				this.breadcrumbList = this.breadcrumbListNew;
				this.getThumbList();
			})
			this.dialogImagesVisible = false;
		},
		handleImagesPopoverCurrentChange(data, node){
			this.uploadForm.pname = data.name;
			this.uploadForm.pid = data.material_id;
			let breadcrumb = [];
			var getBreadcrumb= function(item){
				breadcrumb.unshift(item.data);
				if(item.data.parent_id>0){
					getBreadcrumb(item.parent);
				}
			}
			getBreadcrumb(node);
			this.breadcrumbListNew = breadcrumb;
			this.popoverDialogImages = false;
		},
		async httpRequestMaterialUpload(value){
			const { file } = value
			const isType = ['image/jpeg','image/png','image/gif'].includes(file.type);
			const isSize = file.size / 1024 / 1024 < 3;
			if (!isType) {
				this.$message.error(this.$t('seller.upload.imageType2'));
				return false;
			}
			if (!isSize) {
				this.$message.error(this.$t('seller.upload.imageSize3M'));
				return false;
			}
			
			this.loadingUplaod = true;
			const { code } = await materialOSSUpload(this.uploadForm.pid,0,file,'material')
			if(code == 200){
				this.$message.success(this.$t('seller.successMsg.upload'))
			}
			this.loadingUplaod = false;
		},
		handleFolderShow(){
			this.folderForm.pname = this.currentNodename;
			this.folderForm.pid = this.currentNodekey;
			this.folderForm.name = '';
			this.dialogFolderVisible = true;
			this.$nextTick(()=>{
				this.$refs.addFolderTree.setCurrentKey(this.folderForm.pid);
			})
		},
		handleFolderClose(){
			this.dialogFolderVisible = false;
		},
		handleFolderPopoverCurrentChange(data, node){
			this.folderForm.pname = data.name;
			this.folderForm.pid = data.material_id;
			this.popoverDialogFolder = false;
		},
		handleFolderSubmit(){
			let that= this;
			if(!that.folderForm.name){
				that.$message.error(this.$t('seller.errorMsg.newFolderName'));
				return false;
			}
			
			let param = {
				name: that.folderForm.name,
				parent_id: that.folderForm.pid
			}
			createFolder(param).then(() => {
				that.folderForm.name = ''
				that.getFolderList()
				that.$message.success(this.$t('seller.successMsg.create'))
				that.dialogFolderVisible = false
			})
		},
		handleShiftShow(){
			this.shiftForm.id = this.currentNodekey;
			this.dialogShiftVisible = true;
		},
		handleShiftClose(){
			this.dialogShiftVisible = false;
		},
		handleShiftCurrentChange(data, node){
			this.shiftForm.id = data.material_id;
		},
		handleShiftSubmit(){
			let ids = this.checkedThumb.join(',');
			if(!ids){
				this.$message.error(this.$t('seller.errorMsg.selectFile'));
				return false;
			}
			let param = {
				material_ids: ids,
				parent_id: this.shiftForm.id
			}
			deleteMetarial(param).then(() => {
				this.getThumbList()
				this.$message.success(this.$t('seller.successMsg.move'))
				this.dialogShiftVisible = false
			})
		},
		// 复制
		handleCopy:function(text){
			let inputObj = document.createElement('input');
			inputObj.value = text;
			document.body.appendChild(inputObj);
			inputObj.select();
			document.execCommand("Copy");
			this.$message.success(this.$t('seller.successMsg.copy'));
			inputObj.remove();
		},
		// 双击打开
		handleOpenFolder(data){
			this.picScreen.status = '-1';
			this.currentNodename = data.name;
			this.currentNodekey = data.material_id;
			this.defaultExpandedKeys.push(data.material_id);
			this.$nextTick(()=>{
				this.$refs.folderList.setCurrentKey(this.currentNodekey);
			})
			if(this.breadcrumbList.length>0){
				this.breadcrumbList.push(data);
			}
			this.getThumbList();
			this.checkThumbAll = false;
			this.checkedThumb = [];
			this.isIndeterminate = false;
		},
		handleOpenImage(item){
			this.thumbIndex = this.thumbView.indexOf(item);
			this.dialogThumbVisible = true;
		},
		dialogThumbOpen(){
			this.$nextTick(function(){
				this.$refs.thumbCarousel.setActiveItem(this.thumbIndex);
			})
		},
		handleThumbClose(){
			this.dialogThumbVisible = false;
		},
		handleFolderEditShow(item){
			this.folderItem = JSON.parse(JSON.stringify(item));
			this.dialogFolderEditVisible = true;
		},
		handleFolderEditClose(){
			this.dialogFolderEditVisible = false;
		},
		handleFolderEditSubmit(){
			let that= this;
			if(!that.folderItem.name){
				that.$message.error(this.$t('seller.errorMsg.renameFile'));
				return false;
			}
			let param = {
				name: that.folderItem.name,
				material_id: that.folderItem.material_id
			}
			renameFolder(param).then(response => {
				that.folderItem = {};
				that.getFolderList();
				that.$message.success(this.$t('seller.successMsg.renameFile'));
				that.dialogFolderEditVisible = false;
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.material-container {
		display: flex;
		height: 100%;
		box-sizing: border-box;
		.material-menu {
			width: 160px;
			height: 100%;
			display: flex;
			flex-direction: column;
			background-color: #f9f9f9;
			border-left: 1px solid #d8d8d8;
			.material-menu-main {
				flex: 1;
			}
			.material-menu-foot {
				li {
					height: 37px;
					text-align: center;
					.pic-del {
						margin: 0;
						color: #666666;
					}
					.el-icon-delete {
						font-size: 14px;
						margin-right: 4px;
					}
				}
				li>div {
					margin: 0 12px;
					color: #979797;
					cursor: pointer;
					height: 37px;
					line-height: 37px;
					border-top: 1px solid #f0f0f0;
					box-sizing: border-box;
				}
			}
			::v-deep .el-tree {
				padding-top: 10px;
				background: none;
				.el-tree-node.is-current>div.el-tree-node__content {
					.el-tree-node__label {
						color: $--seller-primary;
					}
				}
			}
		}
		.material-main {
			flex: 1;
			height: 100%;
			display: flex;
			position: relative;
			.material-wrap {
				width: 100%;
				height: 100%;
				position: relative;
				.material-wrap-notice {
					height: 30px;
					margin: 5px;
					background-color: #FFF1E8;
					font-size: 12px;
					text-align: center;
					line-height: 30px;
					border-radius: 2px;
					a {
						color: #3089DC;
						padding-left: 8px;
					}
				}
				.material-wrap-head {
					height: 44px;
					line-height: 28px;
					padding: 8px 10px 8px 20px;
					position: relative;
					box-sizing: border-box;
					background-color: #f6f6f6;
					.material-wrap-head-main {
						float: left;
						.uu-icon {
							width: 20px;
							height: 20px;
							display: inline-block;
							vertical-align: middle;
							background-size: auto 20px;
							background-repeat: no-repeat;
							background-image: url(~@/assets/images/seller/ic-material-head.png);
						}
						.uu-icon-vertical {
							background-position: 0 0;
						}
						.uu-icon-vertical.active {
							background-position: -20px 0;
						}
						.uu-icon-sort {
							background-position: -80px 0;
						}
						li {
							float: left;
							margin-right: 15px;
						}
						li:last-child {
							margin-right: 0;
						}
						a {
							color: #4A90E2;
						}
						.el-icon-setting {
							margin-right: 4px;
						}
						.clera {
							float: left;
							height: 16px;
							width: 1px;
							margin: 6px 20px 0 0;
							vertical-align: text-top;
							border-left: 1px solid #ccc;
						}
						.material-wrap-head-rank {
							float: left;
							width: 24px;
							cursor: pointer;
						}
					}
					.material-wrap-head-bar {
						float: right;
					}
				}
				.material-wrap-action {
					height: 59px;
					padding: 12px 20px 0;
					margin-bottom: 16px;
					box-sizing: border-box;
					.material-wrap-action-main {
						float: left;
						.el-breadcrumb {
							margin-bottom: 15px;
						}
						.material-wrap-action-sele {
							.con-sele-text {
								font-size: 12px;
								display: inline-block;
							}
							.con-sele-button {
								display: inline-block;
								height: 24px;
								line-height: 24px;
								border: 1px solid rgb(216, 216, 216);
								border-radius: 2px;
								margin-left: 10px;
								.sele-button {
									padding: 0 20px;
									cursor: pointer;
								}
								.sele-icon {
									color: rgb(216, 216, 216);
								}
							}
						}
					}
					.material-wrap-action-box {
						float: right;
						display: flex;
						padding-top: 16px;
						a {
							color: #4A90E2;
						}
						.pic-storage {
							width: 214px;
							margin-left: 8px;
						}
						.storage-content {
							font-size: 12px;
							color: #686868;
							line-height: 15px;
							margin-bottom: 7px;
							a {
								float: right;
							}
							.el-icon-star-off {
								color: #FFC900;
								font-size: 14px;
								margin-right: 3px;
							}
						}
						.storage-bar {
							width: 100%;
							height: 5px;
							overflow: hidden;
							background-color: #D8D8D8;
						}
						.storage-bar-used {
							width: 0;
							height: 5px;
							display: inline-block;
							vertical-align: top;
							background: linear-gradient(90deg, #53C6F6 2%, #3089DC 100%);
							border-radius: 2px;
						}
					}
				}
				.material-wrap-main {
					height: calc(100% - 175px);
					.material-list {
						overflow: hidden;
						li {
							float: left;
							width: 130px;
							margin: 0 0 13px 19px;
							cursor: pointer;
							position: relative;
							.li-img {
								width: 130px;
								height: 130px;
								overflow: hidden;
								border: 1px solid rgba(204,204,204,0.5);
								position: relative;
								background-color: #f9f9f9;
								::v-deep .el-checkbox {
									.el-checkbox__input {
										top: 8px;
										left: 8px;
										position: absolute;
									}
									.el-checkbox__label {
										width: 130px;
										height: 130px;
										padding: 0;
									}
								}
								img {
									width: 100%;
									height: 100%;
									object-fit: scale-down;
								}
								.li-img-foot {
									left: 0;
									bottom: 0;
									width: 128px;
									height: 26px;
									line-height: 26px;
									position: absolute;
									display: none;
									text-align: center;
									color: #666;
									font-size: 12px;
									cursor: pointer;
									background-color: rgba(0,0,0,.7);
									a {
										color: #fff;
										font-size: 15px;
										margin: 0 5px;
									}
								}
							}
							.li-img:hover {
								.li-img-head {
									display: block;
								}
								.li-img-foot {
									display: block;
								}
							}
							.li-name {
								width: 100%;
								height: 46px;
								line-height: 46px;
								font-size: 14px;
								text-align: center;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
				}
				.material-wrap-foot {
					height: 56px;
					padding: 10px 25px 0 0;
					border-top: 1px solid #D8D8D8;
					box-sizing: border-box;
					text-align: center;
				}
			}
		}
	}
	.popover-pic-screen {
		li {
			padding-bottom: 10px;
			border-bottom: 1px solid #E6E6E6;
		}
		li:last-child {
			border-bottom: none;
		}
		.item-tit {
			padding: 14px 0 5px;
			font-size: 12px;
			color: #999999;
			line-height: 12px;
		}
		.item-main {
			line-height: 28px;
		}
	}
	.popover-pic-sarch {
		width: 330px;
		.sarch-title {
			font-size: 12px;
			color: #999999;
		}
		.sarch-item {
			margin-top: 10px;
			.item-label {
				display: inline-block;
				font-size: 12px;
				color: #6a6a6a;
				width: 60px;
				margin-right: 5px;
			}
			.item-block {
				display: inline-block;
				min-width: 200px;
			}
		}
		::v-deep .el-radio {
			.el-radio__label {
				font-size: 12px;
			}
		}
		.popover-pic-sarch-foot {
			padding-top: 10px;
			text-align: right;
			margin-top: 15px;
			border-top: 1px solid #D8D8D8;
		}
	}
	.dialog-images {
		::v-deep .el-dialog {
			.el-dialog__body {
				height: auto;
				padding-left: 20px;
				padding-right: 20px;
			}
			.el-dialog__footer {
				border-top: 1px solid #D8D8D8;
				padding: 18px 20px;
			}
			.dialog-images-head {
				li {
					display: inline-block;
					white-space: nowrap;
					margin-right: 10px;
					.li-label {
						color: #999999;
						margin-right: 8px;
					}
					.li-block {
						display: inline-block;
					}
					a {
						color: #4A90E2;
						cursor: pointer;
					}
					::v-deep .el-checkbox {
						color: #999999;
						margin-right: 5px;
					}
				}
			}
			.dialog-material-foot {
				text-align: left;
				height: 28px;
				line-height: 28px;
				a:hover {
					color: #4A90E2;
					.el-icon-warning-outline {
						font-size: 14px;
						margin-right: 4px;
						transform: rotate(180deg);
					}
				}
				.dialog-material-footbar {
					float: right;
					height: 40px;
				}
			}
		}
	}
	.dialog-material-main {
		margin-top: 36px;
		::v-deep .el-upload {
			width: 100%;
			height: 344px;
			border-radius: 2px;
			box-sizing: border-box;
			.el-upload-dragger {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				background-color: transparent;
				.el-upload__text {
					p {
						margin-top: 20px;
						height: 16px;
						line-height: 16px;
					}
				}
			}
			.el-upload-dragger:hover {
				background-color: #f2f2f2;
			}
		}
	}
	.dialog-folder {
		::v-deep .el-dialog {
			.el-dialog__body {
				height: auto;
			}
		}
		.dialog-folder-main {
			width: 420px;
			margin: 0 auto;
			padding: 30px 0;
			.dialog-folder-title {
				font-size: 12px;
				color: #666666;
				margin-top: 20px;
				margin-bottom: 12px;
			}
			.el-cascader {
				width: 100%;
			}
		}
	}
	.dialog-shift {
		::v-deep .el-dialog {
			.el-dialog__body {
				padding: 20px;
				height: auto;
			}
		}
		.dialog-shift-main {
			height: 260px;
			border-radius: 2px;
			border: 1px solid #CCCCCC;
			::v-deep .el-tree {
				.el-tree-node.is-current>div.el-tree-node__content {
					.el-tree-node__label {
						color: $--seller-primary;
					}
				}
			}
		}
	}
	.uu-tree {
		.el-tree-node.is-current>div.el-tree-node__content {
			.el-tree-node__label {
				color: $--seller-primary;
			}
		}
	}
</style>
